<template>
    <div class="container-sm">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7938.808979245277!2d24.926685000000003!3d60.16910800000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46920a3467ff768b%3A0x6ee61b60af8769de!2sEtel%C3%A4inen%20Rautatiekatu%2014b%2C%2000100%20Helsinki!5e0!3m2!1sen!2sfi!4v1633262260461!5m2!1sen!2sfi" width="75%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    
</template>
<script>
import map from "@/assets/chrome_2021-10-03_14-54-28.png"
export default {
    data() {
        return {
            img: map,
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>
.container-sm {
    margin-top: 60px;
    padding-bottom: 60px;
}
.mapImage {
    width: 75%;
}
</style>