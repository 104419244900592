<template>
    <div class="container-sm">
        <img class="logo" :src="logo">
        <h1>Hammaslaboratorio Takahammas Oy</h1>
            <hr class="hr-white">
    </div>
</template>

<script>
import logoImage from "@/assets/lataus.png"
export default {
    data() {
        return {
            logo: logoImage,
        }
    },
}
</script>

<style scoped>
.container-sm {
    padding-top: 60px;
}
.logo {
    padding-bottom: 20px;
}
.hr-white {
    background-color: white;
    opacity: 1;
}
</style>