<template>
    <div class="container-sm">
        <div class="slides">
            <img class="image-list" :src="images[currentImage]" @click="changeImg()">
        </div>
        <hr class="hr-white">
    </div>
</template>

<script>
import mainImage1 from "@/assets/IMG_8226.jpg"
import mainImage2 from "@/assets/IMG_8265.jpg"
import mainImage3 from "@/assets/IMG_8270.jpg"
import mainImage4 from "@/assets/IMG_8279.jpg"

export default {
    data() {
        return {
            currentImage: 0,
            images: [
                mainImage1,
                mainImage2,
                mainImage3,
                mainImage4
            ]          
        }
    },
    methods: {
        async changeImg() {
            if(this.currentImage < this.images.length-1) {
                this.currentImage++;
            } else {
                this.currentImage = 0;
            }
        }
    },
}
</script>
<style scoped>
.image-list {
    width: 80%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.image-list:hover {
    cursor: pointer;
}
.hr-white {
    background-color: white;
    opacity: 1;
}
</style>