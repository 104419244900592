<template>
    <div class="container-sm">
        <div class="row">
            <div class="col-sm-6">
                <h2>Perttu Takaneva</h2>
                <img class="p_t-img" :src="img1">
                <h3>Erikoishammasteknikko</h3>
            </div>
            <div class="col-sm-6">
                <h2>Tiinamari Takaneva</h2>
                <img class="p_t-img" :src="img2">
                <h3>Toimitusjohtaja</h3>
            </div>
        </div>
        <hr class="hr-white">

        <div class="ajanvaraus">
            <h1>Ajanvaraus</h1>
            <h2>Ma-Pe: 08:00 - 18:00</h2>
            <h2>Puh: +358 9 773 6213</h2>
            <h2>takahammas.oy@hotmail.fi</h2>
            <h2>Osoite:</h2>
            <h3>Eteläinen Rautatiekatu 14 B</h3>
            <h3>00100 Helsinki Finland</h3>

        </div>
        <hr class="hr-white">
    </div>
</template>
<script>
import perttu from '@/assets/Perttu001.png'
import tiinamari from '@/assets/tiinamaritakaneva2.png'

export default {
    data() {
        return {
            img1: perttu,
            img2: tiinamari
        }
    },
}
</script>

<style scoped>
.container-sm {
    margin-top: 60px;
}
.col-sm-6 {
    margin-bottom: 50px;
}
.p_t-img {
    width: 80%;
}
.hr-white {
    background-color: white;
    opacity: 1;
}
</style>