<template>
    <Header/>
    <PictureCollection/>
    <Main/>
    <Map/>
</template>

<script>
import Header from './components/Header.vue'
import PictureCollection from './components/PictureCollection.vue'
import Main from './components/Main.vue'
import Map from './components/Map.vue'

export default {
  name: 'App',
  components: {
    Header,
    PictureCollection,
    Main,
    Map
  }
}
</script>

<style>
html {
  height: 100%;
}
body {
  min-height: 100%;
}
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  color: white;
  margin: auto;
  /*margin-top: 60px;*/
  max-width: 1280px;
  height: 100%;
  background-color: #080F13;
}
</style>
